import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import { BillingDataForm } from "./components/BillingDataForm";
import { PageTitle } from "./components/PageTitle";
import {
  GridContainer,
  StyledBreadcrumbSeparator,
  StyledPageHeaderColumn,
} from "./styled";
import { IncludedServices } from "./components/IncludedServices";
import { Grid } from "@mui/material";
import { PaymentInformation } from "./components/PaymentInformation";
import { PaymentSelection } from "./components/PaymentSelection";
import { FooterBar } from "../FooterBar";
import { Breadcrumb } from "../Breadcrumb";
import { PageHeader } from "@flash-tecnologia/hros-web-ui-v2";
import { useLocation, useNavigate } from "react-router-dom";
import { TermsAndConditionsCheckbox } from "../TermsAndConditionsCheckbox";
import { ConditionsContract, SuccessModal } from "..";
import { PricingBox } from "./components/PricingBox";

export const Checkout = ({
  isSelection,
  setCreditCardData,
  setBillingData,
  price,
  template,
  isPdf,
  paymentMethod,
  setPaymentMethod,
  modules,
  openSuccessModal,
  setOpenSuccessModal,
  loading,
  plan,
}: {
  isSelection: boolean;
  setCreditCardData: Dispatch<SetStateAction<any>>;
  setBillingData: Dispatch<SetStateAction<any>>;
  price: string | undefined;
  template: string | undefined;
  isPdf: boolean;
  paymentMethod: string;
  setPaymentMethod: Dispatch<SetStateAction<string>>;
  modules: string[];
  openSuccessModal: boolean;
  setOpenSuccessModal: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  plan?: string | undefined;
}) => {
  const [openIncludedServices, setOpenIncludedServices] =
    useState<boolean>(false);
  const [openConditionsModal, setOpenConditionsModal] =
    useState<boolean>(false);
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);

  const billingFormRef = useRef<any>(null);
  const creditCardFormRef = useRef<any>(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { from: route } = (state as any) || {};

  const handleSignInContract = useCallback(() => {
    switch (paymentMethod) {
      case "creditCard":
        billingFormRef.current.handleSubmit();
        creditCardFormRef.current.handleSubmit();
        break;
      case "billet":
        billingFormRef.current.handleSubmit();
        break;
    }
  }, [setBillingData, setCreditCardData, paymentMethod]);

  const handleSubmitBillet = useCallback(
    async (props: {
      email: string;
      street: string;
      complement: string;
      zipCode: string;
      state: string;
      district: string;
      city: string;
      number: string;
      phone: string;
    }) => {
      setBillingData(props);
    },
    [setBillingData]
  );

  const handleSubmitCreditCard = useCallback(
    async (props: {
      name: string;
      documentNumber: string;
      cardNumber: string;
      dueDate: string;
      cvv: string;
    }) => {
      setCreditCardData(props);
    },
    [setCreditCardData]
  );

  return (
    <GridContainer container>
      <PageHeader style={{ padding: "0px", margin: "0px" }}>
        <StyledPageHeaderColumn>
          <Breadcrumb
            breadcrumbs={[
              {
                onClick: () => {
                  navigate(`/home`);
                },
                label: "Início",
                active: true,
              },
              {
                onClick: () => ({}),
                label: "Confirme sua assinatura",
              },
            ]}
            separator={
              <StyledBreadcrumbSeparator size={24} name="IconChevronRight" />
            }
            style={{ marginBottom: "24px" }}
          />
        </StyledPageHeaderColumn>
      </PageHeader>
      <PageTitle />
      <Grid item xs={12} lg={7}>
        {isSelection ? (
          <PaymentSelection
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            handleSubmitCreditCard={handleSubmitCreditCard}
            creditCardFormRef={creditCardFormRef}
          />
        ) : (
          <PaymentInformation
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            handleSubmitCreditCard={handleSubmitCreditCard}
            creditCardFormRef={creditCardFormRef}
          />
        )}

        <BillingDataForm onSubmit={handleSubmitBillet} ref={billingFormRef} />
        <TermsAndConditionsCheckbox
          checked={acceptTerms}
          setChecked={setAcceptTerms}
          setOpenConditionsModal={setOpenConditionsModal}
        />
      </Grid>
      <Grid item xs={12} lg={5}>
        <PricingBox price={price} plan={plan} />
        <IncludedServices
          isLoading={false}
          open={openIncludedServices}
          setOpen={setOpenIncludedServices}
          services={modules}
        />
      </Grid>
      <ConditionsContract
        documentLink={template}
        onOpen={openConditionsModal}
        setOpenModal={setOpenConditionsModal}
        setChecked={setAcceptTerms}
        isPdf={isPdf}
      />
      <SuccessModal
        onOpen={openSuccessModal}
        setOpenModal={setOpenSuccessModal}
        title={"A sua assinatura da Flash People já está ativa ⚡"}
        route={route?.pathname ? route?.pathname : "/home"}
      />
      <FooterBar
        loading={loading}
        disabled={acceptTerms}
        nextButtonIcon={"IconCheck"}
        nextHandleClick={() => {
          handleSignInContract();
        }}
        previousHandleClick={() => {
          navigate("/home");
        }}
      />
    </GridContainer>
  );
};
