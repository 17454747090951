import { Outlet, Navigate } from "react-router-dom";

import {
  useSelectedCompany,
  getFromLS,
  usePermissions,
} from "@flash-tecnologia/hros-web-utility";

import { useMemo } from "react";

export const PermissionsRoutesGuard = ({
  children,
}: {
  children?: JSX.Element;
}) => {
  const isLoggedIn = getFromLS("hrosAccessToken");

  // const { selectedCompany } = useSelectedCompany();

  // const { companies, isAdmin } = usePermissions();

  // const companyPermissions = useMemo(() => {
  //   if (companies && selectedCompany?.id) {
  //     const selectedCompanyPermissions = companies.find(
  //       (company) => company.id === selectedCompany.id
  //     );
  //     return selectedCompanyPermissions;
  //   }
  //   return null;
  // }, [companies, selectedCompany]);

  // const isRouteAllowed =
  //   isAdmin || companyPermissions?.permissions.includes("*");

  if (!isLoggedIn) {
    return <Navigate to={"/authentication/login"} />;
  }

  // if (!isRouteAllowed) {
  //   return <Navigate to={"/home"} />;
  // }

  return children ? children : <Outlet />;
};
