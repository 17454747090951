import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  margin-bottom: 24px;
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark1};
  text-align: center;
  margin-bottom: 8px;
  padding: 0px 350px;
`;

export const StyledSubText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
  text-align: center;
  margin-bottom: 48px;
  padding: 0px 330px;
`;

export const VideoPlayerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Player = styled.iframe`
  width: 700px;
  border-radius: 12px;
  height: 400px;
  min-height: 227px;
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 80px 0px;
`;

export const StyledDivContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0px;
`;

export const StyledButton = styled(Button)``;
